<template>
  <div>
    <v-row class="mt-2" align="center" justify="center">
      <v-col class="mt-4">
        <img src="/assets/logo.png">
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
